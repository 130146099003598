<script>
import { gantt } from 'dhtmlx-gantt';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';
import DhmeAssemblyPlanningMixin from '@/modules/daiwa-house-modular-europe/AssemblyPlanning/DhmeAssemblyPlanning.mixin';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';

import { queryTasksV2 } from '@/services/api/task.api';

gantt.plugins({
  marker: true,
});

export default {
  name: 'DhmeAssemblyPlanningChart',
  mixins: [DhmeAssemblyPlanningMixin],
  computed: {
    ...mapGetters({
      plannedTasks: 'dhmeAssemblyPlanningStore/plannedAssemblyTasks',
      assemblyHalls: 'dhmeAssemblyPlanningStore/assemblyHalls',
      assemblyLocations: 'dhmeAssemblyPlanningStore/assemblyLocations',
      focusedHall: 'dhmeAssemblyPlanningStore/focusedHall',
    }),
    splitRenderData() {
      return [
        ...this.assemblyHalls.map((h) => {
          return {
            id: h.id,
            title: h.title,
            text: h.title,
            type: 'project',
            render: 'split',
            open: false,
          };
        }),

        ...this.plannedTasks
          .filter((task) => !!task && !!task.task_project && !!task.task_type)
          .map((task) => ({
            ...(task.id ? { id: task.id } : {}),
            title: `${task.task_project.name}`,
            text: `${task.task_project.name}`,
            description: task.description,
            number: task.number,
            parent: task.task_type.custom_3,
            project: task.task_project.name,
            color: stringToConstantColor(
              `${task.task_project.name}-${task.task_project.id}`
            ),
            group: task.task_type.custom_2,
            start_date: moment(task.planned_start).format('DD-MM-YYYY HH:mm'),
            end_date: moment(task.planned_end).format('DD-MM-YYYY HH:mm'),
          })),
      ];
    },
  },
  watch: {
    focusedHall(hall) {
      if (!hall) {
        this.init();
      }
    },
    plannedTasks(tasks) {
      if (tasks) {
        this.parseData();
      }
    },
    assemblyHalls(halls) {
      if (halls) {
        this.parseData();
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    gantt.clearAll();
    gantt.detachAllEvents();
  },
  methods: {
    init() {
      gantt.ext.zoom.init(this.zoomConfig);
      gantt.ext.zoom.setLevel('week');

      gantt.config.columns = [
        { name: 'title', label: 'Assembly Hall', width: '200' },
      ];

      gantt.templates.rightside_text = (start, end, task) => {
        return null;
      };

      gantt.config.inherit_scale_class = true;
      gantt.config.work_time = true;
      gantt.config.sort = true;
      gantt.config.readonly = true;
      gantt.config.open_split_tasks = false;
      gantt.config.scroll_size = 10;

      const todayMarker = gantt.addMarker({
        start_date: new Date(),
        css: 'today',
        title: 'Now',
        text: 'Now',
      });

      this.currentDateMarkerInterval = setInterval(() => {
        const today = gantt.getMarker(todayMarker);
        if (today) {
          today.start_date = new Date();
          gantt.updateMarker(todayMarker);
        }
      }, 1000 * 3600); // updates marker position every hour

      gantt.renderMarkers();

      gantt.attachEvent('onTaskClick', (id) => {
        const task = this.plannedTasks.find((t) => t.id === id);
        if (task) {
          this.$store.commit('dhmeAssemblyPlanningStore/focus_on_task', task);
        }
      });
      gantt.attachEvent('onTaskDblClick', this.onTaskRowClick);

      gantt.init('gantt_container');

      this.parseData();
    },
    setZoomLevel(level) {
      gantt.ext.zoom.setLevel(level);
    },
    showDate(data) {
      gantt.showDate(data);
    },
    parseData() {
      gantt.clearAll();
      gantt.parse({
        data: this.splitRenderData,
      });
      this.showDate(new Date());
    },
    getLaneColor(lane) {
      if (lane) {
        console.log(lane);
        let l = this.assemblyLocations
          .sort(
            (a, b) =>
              parseInt(a.assembly_location) - parseInt(b.assembly_location)
          )
          .find((item) => item.assembly_lane === lane);
        return stringToConstantColor(`${lane}${l.id}`);
      }
    },
    async onTaskRowClick(id) {
      const hall = this.assemblyHalls.find((h) => h.id === id);
      if (hall) {
        let plannedTasks = this.plannedTasks.filter(
          (t) => t.task_type.custom_3 === hall.id
        );

        if (plannedTasks.length > 0) {
          this.$store.commit('dhmeAssemblyPlanningStore/focus_on_hall', hall);
          this.$store.commit('dhmeAssemblyPlanningStore/make_request');

          gantt.config.columns = [
            {
              name: 'title',
              label: 'Location',
              width: '100',
              template: (task) => {
                return `<div class="d-flex full-height">
                    <div class="lane-color-block mr-2" style="background-color: ${this.getLaneColor(
                      task.lane
                    )}"></div>
                    <div>
                      ${task.location}
                    </div>
                  </div>`;
              },
            },
          ];

          const { tasks } = await queryTasksV2(plannedTasks[0].license, [
            {
              column: 'type',
              operator: '=',
              values: ['dhme-module-assembly'],
            },
            {
              column: 'project',
              operator: '=',
              values: plannedTasks
                .filter((t) => t.task_project)
                .map((t) => t.task_project.id),
            },
          ]);

          gantt.attachEvent('onTaskClick', (id) => {
            const task = tasks.find((t) => t.id === id);
            const parent = this.plannedTasks.find((t) => t.id === task.parent);
            this.$store.commit(
              'dhmeAssemblyPlanningStore/focus_on_task',
              parent
            );
          });

          gantt.clearAll();
          gantt.parse({
            data: [
              ...this.assemblyLocations
                .filter((l) => l.assembly_hall === hall.id)
                .sort(
                  (a, b) =>
                    parseInt(a.assembly_location) -
                    parseInt(b.assembly_location)
                )
                .map((l) => {
                  return {
                    id: l.id,
                    lane: l.assembly_lane,
                    location: l.assembly_location,
                    title: `${l.assembly_lane}-${l.assembly_location}`,
                    text: `${l.assembly_lane}-${l.assembly_location}`,
                    type: 'project',
                    render: 'split',
                    open: false,
                  };
                }),

              ...tasks
                .filter((t) => t.task_type.custom_3)
                .map((task) => {
                  return {
                    id: task.id,
                    title: task.sbscode.label ?? task.sbscode.code,
                    text: task.sbscode.label ?? task.sbscode.code,
                    description: task.description,
                    number: task.number,
                    parent: task.task_type.custom_3,
                    project: task.task_project.name,
                    color: stringToConstantColor(
                      `${task.task_project.name}-${task.task_project.id}`
                    ),
                    start_date: moment(task.planned_start).format(
                      'DD-MM-YYYY HH:mm'
                    ),
                    end_date: moment(task.planned_end).format(
                      'DD-MM-YYYY HH:mm'
                    ),
                  };
                }),
            ],
          });

          this.$store.commit('dhmeAssemblyPlanningStore/request_success');
        } else {
          this.$store.commit('dhmeAssemblyPlanningStore/focus_on_hall', null);
          this.$store.commit('showNotification', {
            content: 'No planning found for this hall',
            color: 'warning',
          });
        }
      }
    },
  },
};
</script>

<template>
  <div
    id="gantt_container"
    class="flex-1 overflow-hidden"
    style="border-radius: 10px"
  />
</template>

<style lang="scss" scoped>
@import '~dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';

::v-deep {
  .lane-color-block {
    width: 5px;
    height: calc(100% - 8px);
    margin: 4px 0;
    border-radius: 15px;
  }

  .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }

  .gantt_task_row.gantt_selected .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }

  .gantt_scale_cell {
    user-select: none;

    &.current_date {
      background-color: lightgray;
    }
  }

  .gantt_task_cell {
    &.current_date {
      background-color: lightgray;
    }
  }

  .gantt_tree_indent {
    width: 10px;
  }

  .status {
    background-color: #1de9b6;
  }
}
</style>
