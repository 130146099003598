import moment from 'moment';
import { addBusinessDays } from '@/modules/daiwa-house-modular-europe/ProductionPlanning/dhme-production-planning.utils';

import { queryTasksV2 } from '@/services/api/task.api';

export async function generateManualPlanning(
  locations,
  startHour,
  workHours,
  startDate,
  generationMethod,
  modules,
  locationTypeFilter,
  assemblyTask,
  assemblyTasks,
  vacationDays,
  alignedPhase,
  locationPlanType
) {
  const DEFAULT_WORKLOAD_HOURS = 8; // Define a default workload if not provided

  let tasks = [];

  // Fetch aligned tasks if an aligned phase is provided
  let alignedTasks = [];
  if (alignedPhase) {
    const { tasks: alignedPhaseTasks } = await queryTasksV2(
      alignedPhase.license,
      [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-module-assembly'],
        },
        {
          column: 'project',
          operator: '=',
          values: [alignedPhase.task_project.id],
        },
      ]
    );
    alignedTasks = alignedPhaseTasks;
  }

  // Convert vacationDays to moment objects
  vacationDays = vacationDays.map((date) => moment(date));

  let currentLaneId = locations[0].assembly_lane;
  let generationMethodDayDelta = 0;

  // Initialize location scheduling times
  locations.forEach((l) => {
    if (alignedPhase) {
      // Get the latest planned_end date for this location
      let locationEndDates = alignedTasks
        .filter((t) => t.options.custom_3 === l.id)
        .map((t) => moment(t.planned_end));

      if (locationEndDates.length > 0) {
        l.current = moment.max(locationEndDates).clone();
      } else {
        l.current = moment(alignedPhase.planned_start).set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      // Ensure l.current is not before alignedPhase.planned_start
      if (l.current.isBefore(moment(alignedPhase.planned_start))) {
        l.current = moment(alignedPhase.planned_start).set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      l.dayEnd = l.current.clone().set({
        hour: startHour + workHours,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      if (l.current.isSameOrAfter(l.dayEnd)) {
        l.current = addBusinessDays(l.dayEnd, 1, vacationDays).set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        l.dayEnd = l.current.clone().set({
          hour: startHour + workHours,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }
    } else {
      l.current = moment(startDate).set({
        hour: startHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      l.dayEnd = l.current.clone().set({
        hour: startHour + workHours,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }

    // Update generationMethodDayDelta based on the generation method
    switch (generationMethod) {
      case 'pendulum':
        if (l.assembly_lane !== currentLaneId) {
          // Alternate between 0 and 1 when changing lanes
          generationMethodDayDelta = generationMethodDayDelta === 0 ? 1 : 0;
        }
        break;
      case 'waterfall':
        if (l.assembly_lane !== currentLaneId) {
          generationMethodDayDelta++;
        }
        break;
      default:
        // Handle other generation methods or set default behavior
        break;
    }

    // Adjust current and dayEnd according to generationMethodDayDelta
    l.current = addBusinessDays(
      l.current,
      generationMethodDayDelta,
      vacationDays
    ).clone();
    l.dayEnd = addBusinessDays(
      l.dayEnd,
      generationMethodDayDelta,
      vacationDays
    ).clone();

    currentLaneId = l.assembly_lane;
  });

  // Ensure that modules have assembly_workload
  modules.forEach((m) => {
    if (
      typeof m.assembly_workload === 'undefined' ||
      isNaN(parseInt(m.assembly_workload))
    ) {
      // Assign a default workload if not provided
      m.assembly_workload = DEFAULT_WORKLOAD_HOURS;
    }
  });

  for (let index = 0; index < modules.length; index++) {
    let m = modules[index];

    let moduleWorkload = parseInt(m.assembly_workload);
    if (isNaN(moduleWorkload)) {
      // Assign default workload if invalid
      moduleWorkload = DEFAULT_WORKLOAD_HOURS;
    }

    let modulePlanned = false;

    // Filter locations based on module type
    let moduleTypeLocations = Object.keys(locationTypeFilter).filter((key) =>
      locationTypeFilter[key].includes(m.module_type)
    );
    let task = {
      title: `Assembly module '${m.module_id}'`,
      project: assemblyTask.task_project.id,
      parent: assemblyTask.id,
      sbscode: m.build_nr,
      options: {
        type: 'dhme-module-assembly',
        custom_1: m.module_id,
      },
    };

    let plannedTask = assemblyTasks.find(
      (t) => t?.sbscode?.code === task?.sbscode
    );

    // Set id to update if task already exists
    if (plannedTask) {
      task.id = plannedTask.id;
    }

    while (!modulePlanned) {
      // Filter and sort locations
      let availableLocations = locations.filter((l) =>
        moduleTypeLocations.length > 0
          ? moduleTypeLocations.includes(l.id)
          : true
      );

      let sortedLocations = availableLocations.sort((a, b) =>
        a.current.diff(b.current)
      );

      let location = sortedLocations[0];

      task.options.custom_2 = location.assembly_hall;
      if (locationPlanType === 'automated') {
        task.options.custom_3 = location.id;
        task.options.custom_11 = location.id;
      }

      let startDate = location.current.clone().add(index, 'seconds');

      let remainingWorkload = moduleWorkload * 60; // Convert to minutes

      let plannedStart = startDate.clone();
      let plannedEnd;

      while (remainingWorkload > 0) {
        let availableMinutes = location.dayEnd.diff(
          location.current,
          'minutes'
        );

        let minutesToSchedule = Math.min(remainingWorkload, availableMinutes);

        plannedEnd = location.current.clone().add(minutesToSchedule, 'minutes');

        remainingWorkload -= minutesToSchedule;

        location.current = plannedEnd.clone();

        if (remainingWorkload > 0) {
          // Move to next business day
          location.current = addBusinessDays(
            location.dayEnd,
            1,
            vacationDays
          ).set({
            hour: startHour,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

          location.dayEnd = location.current.clone().set({
            hour: startHour + workHours,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
        }
      }

      task.planned_start = plannedStart.format('YYYY-MM-DD HH:mm:ss');
      task.planned_end = plannedEnd.format('YYYY-MM-DD HH:mm:ss');
      task.due = task.planned_end;

      // Update location.current and location.dayEnd if necessary
      if (location.current.isSameOrAfter(location.dayEnd)) {
        location.current = addBusinessDays(
          location.dayEnd,
          1,
          vacationDays
        ).set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        location.dayEnd = location.current.clone().set({
          hour: startHour + workHours,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      modulePlanned = true;
    }

    tasks.push(task);
  }

  return tasks;
}

export async function generateAutomatedPlanning(
  locations,
  startHour,
  workHours,
  startDate,
  tactsPerDay,
  modules,
  hallId,
  assemblyTask,
  assemblyTasks,
  vacationDays,
  alignedPhase
) {
  let tasks = [];

  const tactTimeInMinutes = (workHours * 60) / tactsPerDay;

  let assemblyStart = moment(startDate).clone().set({
    hour: startHour,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  if (alignedPhase) {
    const { tasks } = await queryTasksV2(alignedPhase.license, [
      {
        column: 'type',
        operator: '=',
        values: ['dhme-module-assembly'],
      },
      {
        column: 'project',
        operator: '=',
        values: [alignedPhase.task_project.id],
      },
    ]);

    assemblyStart = moment(tasks[tasks.length - 1].planned_start)
      .clone()
      .add(tactTimeInMinutes, 'minutes');
  }

  let assemblyEnd = assemblyStart.clone().set({
    hour: startHour + workHours,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  if (assemblyStart.isSame(assemblyEnd)) {
    assemblyStart = addBusinessDays(assemblyStart, 1, vacationDays)
      .clone()
      .set({
        hour: startHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    assemblyEnd = assemblyStart.clone().set({
      hour: startHour + workHours,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  modules.forEach((m) => {
    let task = {
      title: `Assembly module '${m.module_id}'`,
      project: assemblyTask.task_project.id,
      parent: assemblyTask.id,
      sbscode: m.build_nr,
      options: {
        type: 'dhme-module-assembly',
        custom_1: m.module_id,
        custom_2: hallId,
      },
    };

    let plannedTask = assemblyTasks.find(
      (t) => t?.sbscode?.code === task?.sbscode
    );

    // set id to update
    if (plannedTask) {
      task.id = plannedTask.id;
    }

    let startDate = moment(assemblyStart).clone();

    let endDate = startDate
      .clone()
      .add(locations.length * tactTimeInMinutes, 'minutes');

    while (endDate.isAfter(assemblyEnd)) {
      let diff = endDate.diff(assemblyEnd, 'minutes');

      endDate = addBusinessDays(endDate, 1, vacationDays)
        .clone()
        .set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .add(diff, 'minutes');

      assemblyEnd = endDate.clone().set({
        hour: startHour + workHours,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }

    task.planned_start = startDate.format('YYYY-MM-DD HH:mm:ss');
    task.planned_end = endDate.format('YYYY-MM-DD HH:mm:ss');
    task.due = task.planned_end;

    assemblyStart = startDate.clone().add(tactTimeInMinutes, 'minutes');
    assemblyEnd = startDate.clone().set({
      hour: startHour + workHours,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    if (assemblyEnd.isSame(assemblyStart)) {
      assemblyStart = addBusinessDays(assemblyStart, 1, vacationDays)
        .clone()
        .set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      assemblyEnd = assemblyStart.clone().set({
        hour: startHour + workHours,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }
    tasks.push(task);
  });

  return tasks;
}
